import { Deficiency } from "../Deficiency"

export const NitrogenDeficiency: Deficiency = {
  id: "nitrogen deficiency",
  description: "A nitrogen deficiency in cannabis plants means that the plant is not getting enough nitrogen, which is an essential nutrient for its growth and health. Nitrogen deficiency can cause the lower leaves to turn yellow and wither, as the plant transfers the nitrogen to the newer leaves. It can also lead to stunted growth and poor yields. Nitrogen deficiency is common, especially during the vegetative stage, and can be corrected by adding nitrogen-rich fertilizers.",
  thumbnail: "../images/deficiencies/nitrogen-deficiency-cannabis-450x338.jpg",
  filters: [
    "yellow leaves",
    "wilting leaves"
  ],
  html: "/html/nitrogen_deficiency.html",
  relatedImages: [
    {
      id: "nitrogen_deficiency",
      description: "start of a nitrogen deficiency (leaves turn pale)",
      imageUrl: "./images/deficiencies/nitrogen/almost-nitrogen-deficiency-too-pale.jpeg"
    },
    {
      id: "nitrogen_deficient_leaf",
      description: "nitrogen deficient leaf",
      imageUrl: "./images/deficiencies/nitrogen/nitrogen-deficiency-cannabis.jpg"
    },
    {
      id: "nitrogen_deficiency_closeup",
      description: "A closeup of a nitrogen deficiency",
      imageUrl: "./images/deficiencies/nitrogen/nitrogen-deficiency-wilted-leaf-sm.jpg"
    },
    {
      id: "nitrogen_deficiency_example",
      description: "example of a nitrogen deficiency (lower leaves turn yellow and fall off)",
      imageUrl: "./images/deficiencies/nitrogen/nitrogen-deficiency-yellow-leaf.jpeg"
    },
    
  ],
  howToFix: "Sometimes a cannabis potassium deficiency (like all deficiencies) can be triggered by stressful conditions (for example overwatering, heat, transplant, etc) and may clear up on its own after the period of stress is over. If you only see one or two affected leaves near the bottom of the plant, and the problem isn’t spreading, I wouldn’t worry too much about it!",
  solutions: [
    {
      id: "pH",
      headline: "Adjust pH to Correct Range",
      message: "When the pH at the root zone of a cannabis plant is not in the right range, it will not be able to absorb phosphorus through it's roots. Therefore the first step is to make sure your in the correct pH range for your growth medium. \n\n Phosphorus is best absorbed in soil at a root pH of 6.2 - 7.0, and hydro at a root pH of 5.5 - 6.2.",
      products: 
      [
        {
          id: "vivosun-ph-pen",
          imageUrl: './images/products/vivosun_ph_pen.jpg',
          href: "https://amzn.to/3HkAnFj",
          title: "VIVOSUN 3-in-1 Digital pH Meter",
          price: "$27.99"
        },
      ]
    },
    {
      id:"water-properly",
      headline: "Water plants properly",
      message: "<p>Soil that is overwatered and compact can trigger a phosphorus deficiency to appear even when all factors are perfect. A good rule-of-thumb is to water when the medium feels dry up to the first knuckle, or about an inch.</p><p>If growing in <b>soil</b>, water your plants when the soil feels dry up to your first knuckle, or if the pot feels light.</p><p>If growing in <b>coco coir</b>, water your plants every 1-2 days. If it's staying we for longer than 3 days, try giving it less water at a time, until the plants get bigger and start drinking more. Don't wait for it to dry out, but don't water if the top inch feels 'wet'. If the container feels light, then it's definitely time to water.</p>",
      products: [
        {
          id: "watering-can",
          imageUrl: "./images/products/watering_can.jpg",
          href: "https://amzn.to/48wGiCW",
          title: "Qilebi 2 Gallon Watering Can for Outdoor Plants, Modern Watering Cans for House Plant Garden Flower, Plastic Watering Cans with Removable Nozzle and Long Spout",
          price: "$24.99"
        }
      ]
    },
    {
      id: "maintain-correct-temperature",
      headline: "Maintain correct temperature",
      message: "<p>Cooler temperatures lower than 60&#176;F (15&#176;C), as well as large temperature swings, can make it harder for the plant to absorb phosphorus. Cannabis plants are therefore more likely to show signs of phosphorus deficiency when the temperature drops too low, or they go through a cold spell.</p>",
      products: [
        {
          id: "vivosun-e6-growtent-fan",
          imageUrl: "./images/products/vivosun_e6_grow_tent_fan.jpg",
          href: "https://amzn.to/3vFwUOO",
          title: "VIVOSUN AeroWave E6 Grow Tent Fan 6”, EC Motor, Smart Wifi Control, US Patented Auto Oscillating Clip fan, Weatherproof IP-54, Strong Airflow but Quiet for Hydroponic Ventilation Circulation",
          price: "$39.99"
        }
      ]
    }
  ],
}