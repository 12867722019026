import React from 'react';
import { Provider } from 'react-redux';
import FlagsProvider from './utils/FlagsProvider';
import axios from 'axios';
import store from './redux/store';
import { BrowserRouter, Routes as Switch, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home';


function App() {
  return (
    <div className="App">
      <FlagsProvider
        defaults={{
          API_URL: process.env.REACT_APP_API_URL,
          API_KEY: process.env.REACT_APP_API_KEY,
          MODEL: process.env.REACT_APP_MODEL,
          MODEL_VERSION: process.env.REACT_APP_VERSION,
        }}>
        <Provider store={store}>
          <BrowserRouter basename='/'>
            <Switch>
              <Route path='/' element={<HomePage />} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </FlagsProvider>
    </div>
  );
}


axios.defaults.baseURL = "https://us-central1-cannavizion-a4c6c.cloudfunctions.net/api";

export default App;