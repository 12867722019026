import { Deficiency } from "../Deficiency"

export const Aphids: Deficiency = {
  id: "aphids",
  description: "Aphids are soft-bodied cannabis-loving insects which can appear white, green, yellow, black, brown, and red, depending on their stage of life and where they live. Aphids pierce cannabis leaves with their sucking mouth-parts and feed on the juices inside. They usually occur in colonies located mainly on the undersides of stems and leaves. If a cannabis plant becomes heavily-infested, its leaves can turn yellow and/or wilt due to excessive stress and leaf damage. Look under the leaves to tell if you have an aphid infestation.",
  thumbnail: "../images/deficiencies/nitrogen-deficiency-cannabis-450x338.jpg",
  filters: [
    "yellow leaves",
    "wilting leaves"
  ],
  html: "/html/nitrogen_deficiency.html",
  relatedImages: [
    {
      id: "aphids_stage_of_life",
      description: "Aphids look very different depending on their stage of life. In this picture, the bigger, rounder bugs are adult aphids, while the white, smaller, thinner bugs are young aphids (nymphs). Note: If you’re seeing white bugs that look like tiny fat worms, you may actually have thrips.",
      imageUrl: "./images/deficiencies/aphids/aphids-cannabis-1.jpg"
    },
    {
      id: "aphids_color",
      description: "Sometimes the aphids that attack marijuana are dark-colored or black. This bud is aphid city!",
      imageUrl: "./images/deficiencies/aphids/aphid-city-marijuana-bud.jpeg"
    },
    {
      id: "aphids_stages_of_xlife_2",
      description: "Here’s another example showing aphids of different colors and at different stages of life.",
      imageUrl: "./images/deficiencies/aphids/aphids-of-all-life-stages-bottom-of-cannabis-leaf.jpg"
    },
    {
      id: "aphids_young_red",
      description: "Because many aphids that attack cannabis are green, sometimes people don’t recognize aphids when they’re a different color (like these young aphids which appear red)",
      imageUrl: "./images/deficiencies/aphids/red-green-aphids-cannabis-sm.jpg"
    },
    {
      id: "aphids_europe",
      description: "These aphids from Europe are pale green with dark legs and red eyes",
      imageUrl: "./images/deficiencies/aphids/aphids-cannabis-germany-1024x576.jpg"
    },
    
  ],
  howToFix: "GET RID OF THEM QUICK! Avoid using nervous system insecticides, such as malathion, Dursban (chlorpyrifos), and Orthene (acephate). They are labeled for use on many shade trees and ornamental plants for aphid control, but are not safe to use on cannabis. If something isn't safe to be used on edible plants, then chances are it's not safe to use on cannabis.",
  solutions: [
    {
      id: "check-regularly",
      headline: "Check regularly for signs of aphids",
      message: "The best way to prevent an aphid infestation is to catch it as soon as possible. When growing outdoors it’s pretty difficult to predict when winged “colonizer” aphids will appear, so it’s incredibly important to examine your plants at least weekly to make sure they don’t become infested while you’re not paying attention.Examine the bud area and undersides of the new leaves for clusters or colonies of small aphids (or any other types of bugs). The presence of these colonies indicates that the aphids are established on the plants and their numbers will begin to increase rapidly.",
      products: 
      [   
          {
            id: "sticky-traps",
            href: "https://amzn.to/3U41bRw",
            imageUrl: "./images/products/sticky_traps.jpg",
            title: "Gideal 20-Pack Dual-Sided Yellow Sticky Traps for Flying Plant Insect Such as Fungus Gnats, Whiteflies, Aphids, Leafminers,Thrips - (6x8 Inches, Included 20pcs Twist Ties)",
            price: "$7.99"
          }
      ]
    },
    {
      id:"water-properly",
      headline: "Remove or Spray Off As Many Bugs As Possible",
      message: "If your plant is heavily infested, it’s a good idea to try to cut down their numbers in every way possible. Depending on the infestation, one way to do that may be to simply move your plants outside and spray as many bugs off as you can with a power sprayer. It’s also a good idea to remove leaves and buds that are heavily infected.If possible, spray off as many bugs as you can! An adjustable mister like this one works great because it lets you adjust the power of the spray.",
      products: [
        {
          id: "hand-sprayer",
          href: "https://amzn.to/3TUZ8PK",
          imageUrl: "./images/products/hand_sprayer.jpg",
          title: "VIVOSUN 0.2 Gallon Handheld Garden Pump Sprayer, 27 oz Gallon Lawn & Garden Pressure Water Spray Bottle with Adjustable Brass Nozzle, for Plants and Other Cleaning Solutions (0.8L Red)",
          price: "$16.99"
        }
      ],

    },
    {
      id: "insectidal-soaps",
      headline: "Insecticidal soaps",
      message: "Fatty acid salts or insecticidal soaps can be a good choice against aphids. They weaken the outer shell of aphids but are safe to use on your plants and they don’t leave much of a residue.With soaps, coverage is very important as it does not stay on your plant for long, so follow-up applications may be necessary. Although this is considered safe, avoid getting any on your buds!",
      products: [
        {
          id: "insectidal-soap",
          href: "https://amzn.to/47wmgY0",
          imageUrl:"../images/products/insectidal_soap.jpg",
          title: "Bonide Insecticidal Soap, 32 oz Ready-to-Use Spray Multi-Purpose Insect Control for Organic Gardening Indoor and Outdoor",
          price: "$9.99"
        }
      ]
    },
    {
      id: "neem-oil",
      headline: "Neem Oil",
      message: "Neem Oil will leave an unpleasant taste/smell on buds when used to treat flowering plants, so again, don’t let this stuff get near your buds! There’s also some evidence Neem oil may be harmful to humans so use with care. That being said, Neem oil is an all-natural remedy that is very effective against many different types of bugs and mold. You will need a mister (also called a “One-Hand Pressure Sprayer”) to spray all the leaves evenly, since neem oil and water can separate easily.",
      products: [
        {
          id: "neem-oil",
          href: "https://amzn.to/3SiG0u4",
          imageUrl: "../images/products/neem_oil.jpg",
          title: "Naturevibe Botanicals Neem Oil 32 Ounces Azadirachta Indica | 100% Pure and Natural Neem Leaf Oil | Cold Pressed and Unrefined | Great as Body Oil (946 ml)",
          price: "$19.99"
        }
      ]
    },
    {
      id: "beneficial-insects",
      headline: "Beneficial (predator) Insects",
      message: "<p>Beneficial insects, such as lady beetles, lady bugs, and lacewings may eat large numbers of aphids and are welcome guests in the garden. Although you can order ladybugs to release around your plants, they tend to fly away in just a day or two. Additionally, the reproductive capability of aphids is so great that the impact of the natural enemies may not be enough keep aphids at or below acceptable levels after an infestation has already gotten started.</p>",
      products: [
        {
          id: "lady-bugs",
          href: "https://amzn.to/41RVCaV",
          imageUrl: "../images/products/ladybugs.jpg",
          title: "NaturesArt 1500 Live Ladybugs - Good Bugs for Garden - Ladybug - Guaranteed Live Delivery!",
          price: "$9.99"
        }
      ]
    },
    {
      id: "ants",
      headline: "Get rid of ants if you see them!",
      message: "In some cases, ants naturally “farm” (tend to) aphids in the wild in order to collect their honeydew. How crazy is that? Ants can actually be helping keep your aphid numbers up! So for some cannabis growers, controlling an ant problem can actually help control an aphid problem.",
      products: [
        {
          id: "diatomaceous-earth",
          href: "https://amzn.to/3RYiMb0",
          imageUrl: "../images/products/diatomaceous_earth.jpg",
          title: "Diatomaceous Earth Food Grade 10 Lb",
          price: "$26.99"
        }
      ]
    }
  ],
}