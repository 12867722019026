/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import styles from './results.module.css';
import { deficiencies } from '../../models/deficiencies';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Classification } from '../../redux/reducers/classificationRediucer';
import { TransitionProps } from '@mui/material/transitions/transition';
import RelatedImage from '../related-image/';
import { CloseOutlined } from '@mui/icons-material';
import { RelatedImage as RelatedImageModel } from '../../models/RelatedImage';
import { Deficiency } from '../../models/Deficiency';
import { Product } from '../../models/Product';
import ProductCard from '../product-card/ProductCard';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface Props {
  classification: Classification | null;
  imageUrl: string | null;
  open: boolean;
  onHandleClose: () => void;
}

const Results: React.FC<Props> = ({classification, imageUrl, open, onHandleClose}) => {
  
  const [activeImage, setActiveImage] = useState<RelatedImageModel | null>(null);

  const currentDeficiency = (): Deficiency | undefined => {
    const top =  classification?.top;
    const deficiency: Deficiency | undefined = deficiencies.find(d => d.id === top?.toLocaleLowerCase());
    return deficiency;
  }
  const relatedImages = () => {
   const deficiency: Deficiency | null = currentDeficiency() ?? null;
   return deficiency?.relatedImages;
  }

  const handleClick = (image: RelatedImageModel) => {
    setActiveImage(image)
  }

  const handleProduct = (product: Product) => {
    const { href } = product
    window.open(href, "_blank");
  }

  return (
    <Dialog
      aria-labelledby='result-dialog'
      fullWidth
      TransitionComponent={Transition}
      open={open}>
          <div>

            {classification !== null && (
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
                <IconButton onClick={onHandleClose}>
                  <CloseOutlined />
                </IconButton>
              </div>
            )}

            <div>
            <img
              style={{
                width: '100%'
              }}
              src={imageUrl ?? ""} 
              alt="user_submitted_image" 
            />
            </div>
            
            { classification === null ? (
              <Backdrop
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1
                  }}
                  open={open}
                  onClick={onHandleClose}
                >
          <CircularProgress color="inherit" />
            <Typography sx={{marginTop: '20px'}}>Analyzing image....</Typography>
              </Backdrop>
            ) : (
              <div style={{
                margin: '20px'
              }}>
            
                <div className={styles.scrollableY}>
                
                  <div className={styles.header}>
                      <div className={styles.detected}>
                        <Typography sx={{
                        fontSize: '24px',
                        fontWeight: '700',
                        color: '#333',
                        fontFamily: 'Roboto'}}>
                          DETECTED
                        </Typography>
                
                        <Typography 
                          sx={{
                            color: '#FF0000',
                            fontSize: '24px',
                            fontFamily: 'Roboto',
                            fontWeight: '900'}}>
                            {classification.top}
                        </Typography>
                      </div>
                  </div>

                    <div className={styles.relatedImages}>
                      <Typography sx={{
                        fontSize: '24px',
                        fontWeight: '700',
                        color: '#333',
                        fontFamily: 'Roboto'}}>
                          RELATED IMAGES
                      </Typography>
                        <div className={styles.horizontalScroll}>
                        {
                          relatedImages()?.map((image) => {
                            return (
                               <RelatedImage
                                key={image.id}
                                image={image}
                                onHandleClick={(handleClick)} />
                            )
                          })
                        }
                      </div>
                    </div>
                  <Typography sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#333',
                    fontFamily: 'Roboto',
                    marginTop: '20px'
                  }}>
                    SYMPTOMS
                  </Typography>

                  <Typography gutterBottom sx={{
                      fontSize: '14px',
                      fontFamily: 'Roboto'
                    }}>
                    {currentDeficiency()?.description}
                  </Typography>

                  <Typography sx={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#333',
                    fontFamily: 'Roboto',
                    marginTop: '20px',
                  }}>
                    HOW TO FIX
                  </Typography>
                  <Typography gutterBottom sx={{
                      fontSize: '14px',
                      fontFamily: 'Roboto'
                    }}>
                      {currentDeficiency()?.howToFix}
                  </Typography>

                    <div className={styles.solution}>
                    {currentDeficiency()!.solutions.map((value, index) => {
                      return (
                        <div key={value.id} className={styles.solution}>
                          <div className={styles.headline}>
                            {value.headline}
                          </div>
                          <div className={styles.message}>
                            <iframe srcDoc={value.message} width="100%" frameBorder="0"  scrolling="no"/>
                          </div>
                          <div className={styles.products}>
                          
                            {value.products.map((value, index) => {
                              return (
                                <div onClick={() => handleProduct(value)}>
                                <ProductCard 
                                  key={index}
                                  product={value} />
                                </div>
                            
                
                              );
                            })
                          }
                          </div>

                      </div>
                      )
              
                    })}
                    </div>
                  {/* <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  {
                    (currentDeficiency()?.solutions) && (
                      currentDeficiency()!.solutions.map((value, index) => {
                        return (
                        <div key={value.id} className={styles.solution}>
                          <div className={styles.headline}>
                            {value.headline}
                          </div>
                          <div className={styles.message}>
                            {value.message}
                            <div className={styles.products}>
                               { value.products.map((product: Product) => {
                                  return (
                                    <div className={styles.product}>
                                      <div className={styles.productImg}>
                                        <img src={product.imageUrl} />
                                      </div>
                                    </div>
                                  )
                               })
                              }
                            </div>
                           
                  
                          </div>
                        </div>
                        )
                      })
                    )
                  }
                  
                  </div>
                   */}
                </div>
              </div>
            )}
        
          </div>
        <Backdrop
          open={activeImage !== null}
          onClick={() => setActiveImage(null)}>
            <Dialog open={activeImage !== null}>
              
                <DialogContent sx={{
                  alignSelf: 'center'
                }}>
                    <img 
                      src={activeImage?.imageUrl ?? ""} 
                      style={{
                        width: '100%'
                      }}/>
                      <div className={styles.description}>
                           <center>{activeImage?.description}</center>
                      </div>
                    

                </DialogContent>
            </Dialog>
    
        </Backdrop>
    </Dialog>
  )
}

export default Results;
