import { Deficiency } from "../Deficiency"

export const PhosphorusDeficiency: Deficiency = {
  id: "phosphorus deficiency",
  description: "Phosphorus deficiency in cannabis plants is characterized by discolored leaves, red or purple stems, stiff leaves, or abnormal growth. The deficiency can happen when the roots don’t absorb enough nutrients.",
  thumbnail: "../images/deficiencies/nitrogen-deficiency-cannabis-450x338.jpg'",
  filters: [
    "bronze or brown patches",
      "dark leaves",
      "pink or purple leaves",
      "red stems",
      "shiny or smooth leaves"
  ],
  html: "/html/nitrogen_deficiency.html",
  relatedImages: [
    {
      id: "phosphorus_deficiency_spots",
      description: "a phosphorus deficiency may turn leaves dark green or yellow, and start getting spots or big splotches that look brown, bronze or even a litle blue. ",
      imageUrl: "./images/deficiencies/phosphorus/phosphorus-deficiency-cannabis.jpg"
    },
    {
      id: "phosphorus_deficiency_leaves",
      description: "a phosphorus deficiency may turn leaves dark green or yellow, and start getting spots or big splotches that look brown, bronze or even a litle blue. ",
      imageUrl: "./images/deficiencies/phosphorus/phosphorus-deficiency-bagseed-haze-sm.jpg"
    },
    {
      id: "phosphorus_deficiency_progression",
      description: "the progression of a cannabis phosphorus deficiency",
      imageUrl: "./images/deficiencies/phosphorus/stages-of-phosphorus-deficiency-sm.jpg"
    },
    {
      id: "phosphorus_deficiency_no_deficiency",
      description: "some cannabis strains naturally grow with red or purple stemseven when al their nutrient needs are being fulfilled",
      imageUrl: "./images/deficiencies/phosphorus/red-stems-may-be-first-sign-of-marijuana-phosphorus-deficiency-1024x768.jpg"
    },
    
  ],
  howToFix: "To fix phosphorus deficiency in cannabis, water your plants with cannabis-approved nutrient blends that include phosphorus, don't over or under-water, keep your plants warm, and at the correct pH levels - your soil should be around 6.0 - 6.5pH.",
  solutions: [
    {
      id: "pH",
      headline: "Adjust pH to correct range",
      message: "When the pH at the root zone of a cannabis plant is not in the right range, it will not be able to absorb phosphorus through it's roots. Therefore the first step is to make sure your in the correct pH range for your growth medium. Phosphorus is best absrobved in soil at a root pH of 6.2 - 7.0, and hydro at a root pH of 5.5 - 6.2.",
      products: 
      [
        {
          id: "vivosun-ph-pen",
          imageUrl: './images/products/vivosun_ph_pen.jpg',
          href: "https://amzn.to/3HkAnFj",
          title: "VIVOSUN 3-in-1 Digital pH Meter",
          price: "$27.99"
        },
      
      ]
    },
    {
      id:"nutrients",
      headline: "Give the right nutrients",
      message: "If you suspect you have a iron deficiency even though the pH is correct, or if you believe your system is truly lacking in iron, you may want to consider flushing your system with clean, pH’d water (if on schedule, you can do this alongside a dose of your regular nutrients) and add a supplement that contains Iron, Calcium and Magnesium.",
      products: [
        {
          id: "watering-can",
          imageUrl: "./images/products/watering_can.jpg",
          href: "https://amzn.to/48wGiCW",
          title: "Qilebi 2 Gallon Watering Can for Outdoor Plants, Modern Watering Cans for House Plant Garden Flower, Plastic Watering Cans with Removable Nozzle and Long Spout",
          price: "$24.99"
        }
      ]
    },
    {
      id: "maintain-correct-temperature",
      headline: "Maintain correct temperature",
      message: "<p>Cooler temperatures lower than 60&#176;F (15&#176;C), as well as large temperature swings, can make it harder for the plant to absorb phosphorus. Cannabis plants are therefore more likely to show signs of phosphorus deficiency when the temperature drops too low, or they go through a cold spell.</p>",
      products: [
          {
            id: "vivosun-e6-growtent-fan",
            imageUrl: "./images/products/vivosun_e6_grow_tent_fan.jpg",
            href: "https://amzn.to/3vFwUOO",
            title: "VIVOSUN AeroWave E6 Grow Tent Fan 6”, EC Motor, Smart Wifi Control, US Patented Auto Oscillating Clip fan, Weatherproof IP-54, Strong Airflow but Quiet for Hydroponic Ventilation Circulation",
            price: "$39.99"
          }
      ]
    }
  ],
}