import { Deficiency } from "../Deficiency"

export const PotassiumDeficiency: Deficiency = {
  id: "potassium deficiency",
  description: "With a potassium deficiency, you’ll generally see symptoms on older leaves, but not always. Sometimes you’ll see the symptoms at the top of the plant. Leaves with a potassium deficiency get yellow, brown, or burnt edges and tips. The burnt edges may look a little like nutrient burn, except the affected leaves also start turning yellow in the margins.",
  thumbnail: "../images/deficiencies/potassium-deficiency-cannabis-1-450x338.jpg",
  filters: [
    "bronze or brown patches",
    "brown or yellow leaf tips/edges",
    "curling or clawing leaves"
  ],
  html: "/html/nitrogen_deficiency.html",
  relatedImages: [
    {
      id: "potassium_deficiency_leaf_symptoms",
      description: "Example of a marijuana potassium deficiency with common leaf symptoms.",
      imageUrl: "./images/deficiencies/potassium/potassium-deficiency-weed-yellow-brown-edges-sm.jpg"
    },
    {
      id: "potassium_deficiency_like_iron_deficiency",
      description: "The leaf symptoms appear somewhat similar to an iron deficiency in that they can turn bright yellow, but the tips of the leaves curl as the edges turn brown, burn and die.a phosphorus deficiency may turn leaves dark green or yellow, and start getting spots or big splotches that look brown, bronze or even a litle blue. ",
      imageUrl: "./images/deficiencies/potassium/cannabis-potassium-deficiency-lower-leaves-yellow-sm.jpg"
    },
    {
      id: "potassium_deficiency_overwatering",
      description: "Sometimes a Potassium deficiency is made worse by overwatering, as was the case with this plant.",
      imageUrl: "./images/deficiencies/potassium/cannabis-potassium-deficiency-overwatered-sm.jpg"
    },
    {
      id: "potassium_deficiency_tip_burn",
      description: "Sometimes you’ll get something that looks a lot like tip burn with a potassium deficiency, but it goes in further than nutrient burn, and with a potassium deficiency you also see yellowing between the leaf margins",
      imageUrl: "./images/deficiencies/potassium/potassium-deficiency-marijuana-brown-tips-sm.jpg"
    },
    {
      id: "potassium_deficiency_pale",
      description: "Sometimes the burn can appear pale, bleached or yellow, instead of brown.",
      imageUrl: "./images/deficiencies/potassium/marijuana-potassium-deficiency-yellow-edges-tips-sm.jpg"
    },
    
  ],
  howToFix: "Sometimes a potassium deficiency (like all deficiencies) can be triggered by stressful conditions (for example overwatering, heat, transplant, etc) and may clear up on its own after the period of stress is over. If you only see one or two affected leaves near the bottom of the plant, and the problem isn’t spreading, I wouldn’t worry too much about it!",
  solutions: [
    {
      id: "pH",
      headline: "Adjust the pH to the correct range",
      message: "<p>When the pH at the root zone of a cannabis plant is not in the right range, it will not be able to absorb phosphorus through it's roots. Therefore the first step is to make sure your in the correct pH range for your growth medium.</p><p>Phosphorus is best absrobved in <b>soil</b> at a root pH of <b>6.2 - 7.0</b>, and <b>hydro</b> at a root pH of <b>5.5 - 6.2.</b></p>",
      products: 
      [
        {
          id: "vivosun-ph-pen",
          imageUrl: './images/products/vivosun_ph_pen.jpg',
          href: "https://amzn.to/3HkAnFj",
          title: "VIVOSUN 3-in-1 Digital pH Meter",
          price: "$27.99"
        },
      ]
    },
    {
      id:"water-properly",
      headline: "Ensure proper watering",
      message: "<p>Soil that is overwatered and compact can trigger a phosphorus deficiency to appear even when all factors are perfect. A good rule-of-thumb is to water when the medium feels dry up to the first knuckle, or about an inch.</p><p>If growing in <b>soil</b>, water your plants when the soil feels dry up to your first knuckle, or if the pot feels light.</p><p>If growing in <b>coco coir</b>, water your plants every 1-2 days. If it's staying we for longer than 3 days, try giving it less water at a time, until the plants get bigger and start drinking more. Don't wait for it to dry out, but don't water if the top inch feels 'wet'. If the container feels light, then it's definitely time to water.</p>",
      products: [
        {
          id: "watering-can",
          imageUrl: "./images/products/watering_can.jpg",
          href: "https://amzn.to/48wGiCW",
          title: "Qilebi 2 Gallon Watering Can for Outdoor Plants, Modern Watering Cans for House Plant Garden Flower, Plastic Watering Cans with Removable Nozzle and Long Spout",
          price: "$24.99"
        }
      ]
    },
    {
      id: "maintain-correct-temperature",
      headline: "Maintain correct temperature",
      message: "<p>Cooler temperatures lower than 60&#176;F (15&#176;C), as well as large temperature swings, can make it harder for the plant to absorb phosphorus. Cannabis plants are therefore more likely to show signs of phosphorus deficiency when the temperature drops too low, or they go through a cold spell.</p>",
      products: [
        {
          id: "vivosun-e6-growtent-fan",
          imageUrl: "./images/products/vivosun_e6_grow_tent_fan.jpg",
          href: "https://amzn.to/3vFwUOO",
          title: "VIVOSUN AeroWave E6 Grow Tent Fan 6”, EC Motor, Smart Wifi Control, US Patented Auto Oscillating Clip fan, Weatherproof IP-54, Strong Airflow but Quiet for Hydroponic Ventilation Circulation",
          price: "$39.99"
        }
      ]
    }
  ],
}