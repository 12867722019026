import { NitrogenDeficiency } from "./NitrogenDeficiency";
import { PhosphorusDeficiency } from "./PhosphorusDeficiency";
import { PotassiumDeficiency } from "./PotassiumDeficiency";
import { Aphids } from "./Aphids";
import { Deficiency } from "../Deficiency";
import { IronDeficiency } from "./IronDeficiency";

export const deficiencies: Deficiency[] = [
  NitrogenDeficiency,
  PhosphorusDeficiency,
  PotassiumDeficiency,
  Aphids, 
  IronDeficiency];

export  {
  NitrogenDeficiency,
  PhosphorusDeficiency,
  PotassiumDeficiency,
  IronDeficiency,
};
