import React, { Component } from 'react';
import { Product } from '../../models/Product';
import styles from "./productCard.module.css";

interface ProductCardProps {
  product: Product;
  
}
class ProductCard extends Component<ProductCardProps> {
  render() {

    const {imageUrl, title, price} = this.props.product;
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <img  className={styles.img}src={imageUrl} alt={title} />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>
              {title}
            </div>
            <div className={styles.price}>
              {price}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductCard;