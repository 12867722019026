export enum ActionTypes {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SET_CLASSIFICATION = 'SET_CLASSIFICATION',
  CLEAR_CLASSIFICATION = 'CLEAR_CLASSIFICATION',
  SAVING_CLASSIFICATION = 'SAVING_CLASSIFICSTION',
}

export type ClassificationRequest = {
  imageUrl: string;
  apiUrl: string;
  apiKey: string;
  model: string;
  modelVersion: string;
}