import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux"; 
import { AppDispatch, RootState } from "./store";
import { useEffect, useRef } from "react";
import type { MutableRefObject } from "react";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const useMounted = (): MutableRefObject<boolean> => {
  const isMounted = useRef(true);

  useEffect(
    () => (): void => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
};

export default useMounted;