import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';

interface Props {
  handleFile: Function;
};
const FileUploader = (props: Props) => {

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    const file = e.target.files[0];
    props.handleFile(file);
  }

  return (
    <div style={{
      marginLeft: '20px'
    }}>
      <Typography variant="button">Try It Free!</Typography>
      <input type="file" style={{display: 'none'}} />
      <Button 
        variant='contained'
        onClick={() => hiddenFileInput?.current?.click()}
        style={{
          marginLeft: '20px',
          backgroundColor: 'green'
        }}>
         upload a pic
      </Button>
      <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{display:'none'}} /> 
     
    </div>
  );
};

export default FileUploader;