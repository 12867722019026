import { Deficiency } from "../Deficiency"

export const IronDeficiency: Deficiency = {
  id: "iron deficiency",
  description: "A cannabis iron deficiency is usually seen first on bright yellow new leaves, and the symptoms of a cannabis iron deficiency can sometimes appear alongside other cannabis nutrient problems or deficiencies. An iron deficiency is usually caused by problems with pH, though sometimes a cannabis iron deficiency can be triggered by a stressful environment and may clear up on its own after the period of stress is over.",
  thumbnail: "../images/deficiencies/nitrogen-deficiency-cannabis-450x338.jpg'",
  filters: [
    "bronze or brown patches",
      "dark leaves",
      "pink or purple leaves",
      "red stems",
      "shiny or smooth leaves"
  ],
  html: "/html/iron_deficiency.html",
  relatedImages: [
    {
      id: "iron_deficiency",
      description: " A cannabis iron deficiency is usually seen first on bright yellow new leaves. ",
      imageUrl: "./images/deficiencies/iron/iron-deficiency-cannabis-450x338.jpg"
    },
    {
      id: "iron_deficiecy_2",
      description: "New growth will be pale green or yellow. ",
      imageUrl: "./images/deficiencies/iron/iron-deficiency-yellow-new-leaves-sm.jpg"
    },
    {
      id: "phosphorus_deficiency_progression",
      description: "the progression of a cannabis phosphorus deficiency",
      imageUrl: "./images/deficiencies/iron/iron-deficiency-outdoors-too-much-nutrients.jpg"
    },
    
  ],
  howToFix: "Sometimes a cannabis iron deficiency (like all nutrient deficiencies) can be triggered by stressful conditions, and the plant may recover on its own after the period of stress is over.",
  solutions: [
    {
      id: "pH",
      headline: "Adjust pH to correct range",
      message: "When the pH at the root zone of a cannabis plant is not in the right range, it will not be able to absorb phosphorus through it's roots. Therefore the first step is to make sure your in the correct pH range for your growth medium. Phosphorus is best absrobved in soil at a root pH of 6.2 - 7.0, and hydro at a root pH of 5.5 - 6.2.",
      products: 
      [
        {
          id: "vivosun-ph-pen",
          imageUrl: './images/products/vivosun_ph_pen.jpg',
          href: "https://amzn.to/3HkAnFj",
          title: "VIVOSUN 3-in-1 Digital pH Meter",
          price: "$27.99"
        }
      ]
    },
    {
      id:"nutrients",
      headline: "Give the right nutrients",
      message: "If you suspect you have a iron deficiency even though the pH is correct, or if you believe your system is truly lacking in iron, you may want to consider flushing your system with clean, pH’d water (if on schedule, you can do this alongside a dose of your regular nutrients) and add a supplement that contains Iron, Calcium and Magnesium.",
      products: [
        {
          id: "lotus-calmag",
          imageUrl: "./images/products/lotus_calmag.jpg",
          href: "https://amzn.to/3Sfy0cW",
          title: "Lotus Nutrients Cal-Mag Pro Series - Natural Premium Cal Mag Powder for Plants - Nutrients with Calcium and Magnesium - Hydroponic Coco Coir and Soil - Improve Plant Growth Indoor and Outdoor (15oz)",
          price: "$14.95"
        },

      ]
    },
    {
      id: "roots",
      headline: "Take good care of the roots",
      message: "Iron deficiencies can show up with the plant is having root problems or if the plant is overwatered, even if the pH is right and the iron is there. Proper watering practices help plants grow healthy and avoid a host of problems!",
      products: [
       
      ]
    },
    {
      id:"recovery",
      headline: "Watch for recovery",
      message: "After going through all the above steps, watch to make sure that the iron deficiency starts to clear up within a week or so (try to be patient since iron moves relatively slowly through the plant). The yellow leaves from before may not recover completely, especially if there was a lot of damage, but when new growth is coming in green, you know you’re good to go!",
      products: [
       
      ]
    },
  ],
}