import { Button } from '@mui/material';
import styles from './relatedimage.module.css';
import { RelatedImage as RelatedImageModel } from '../../models/RelatedImage';

type RelatedImageProps = {
  image: RelatedImageModel;
  onHandleClick: (image: RelatedImageModel) => void;
}
const RelatedImage = ({image, onHandleClick}: RelatedImageProps) => {
  return (
      <Button
        onClick={() => onHandleClick(image)}>
        <img 
          src={image.imageUrl}
          alt={image.description} 
          className={styles.img} />
      </Button>
  )
};

export default RelatedImage;