import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage'
import { getFirestore } from "firebase/firestore";
import "firebase/remote-config";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyBhAvfZ1jMRj6LV6J5jobQr1P3zVRBlxj4",
  authDomain: "cannavizion-a4c6c.firebaseapp.com",
  projectId: "cannavizion-a4c6c",
  storageBucket: "cannavizion-a4c6c.appspot.com",
  messagingSenderId: "757783638817",
  appId: "1:757783638817:web:248aae4f2b37d057d0c460",
  measurementId: "G-SXV671K8Q4"
};
// Initialize Firebase
;
const firebase = initializeApp(firebaseConfig);
const storage = getStorage(firebase);
const db = getFirestore(firebase);
const remoteConfig = getRemoteConfig(firebase);

export {
  firebase,
  storage,
  db,
  remoteConfig,
}