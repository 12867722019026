import axios from "axios";
import { db } from "../../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { Dispatch } from "redux";
import { ActionTypes, ClassificationRequest } from "../types";
import { useFlags } from "../../utils/FlagsProvider";
import RemoteConfigKeys from "../../enum/RemoteConfigKeys";

export type Prediction = {
  class: string
  confidence: number
}

export type Classification = {
  imageUrl: string
  top: string
  confidence: number
  createdAt: Date
  trained: boolean
  validated: boolean
  uid: string
  predictions: Prediction[]
}

interface ClassificationState {
  classification: Classification | null;
  loading: boolean;
  error: string | null;
}


interface LoadingAction {
  type: ActionTypes.LOADING;
}

interface SetClassificationAction {
  type: ActionTypes.SET_CLASSIFICATION;
  payload: Classification;
}

interface ErrorAction {
  type: ActionTypes.ERROR;
  payload: string;
}
interface ClearAction {
  type: ActionTypes.CLEAR_CLASSIFICATION;
}

export type ClassificationActionType = 
  | LoadingAction
  | SetClassificationAction
  | ErrorAction
  | ClearAction

const initialState: ClassificationState = {
  classification: null,
  loading: false,
  error: null,
}

  const classificationReducer = (state: ClassificationState = initialState, action: ClassificationActionType): ClassificationState => {
    switch (action.type) {
      case ActionTypes.LOADING:
        return { ...state, loading: true };
      case ActionTypes.ERROR:
        return { ...state, loading: false, error: action.payload}
      case ActionTypes.SET_CLASSIFICATION:
        const classification: Classification = action.payload;
        return { ...state, loading: false, error: null, classification: classification}
      case ActionTypes.CLEAR_CLASSIFICATION:
        return {...state, loading: false, error: null, classification: null}
      default:
        return state;
    }
  }


const classifyImage = (req: ClassificationRequest) => async (dispatch: Dispatch<ClassificationActionType>) => {
  dispatch({type: ActionTypes.LOADING});

  const {apiKey, apiUrl, model, modelVersion, imageUrl} = req;
  const uri = `${apiUrl}/${model}/ ${modelVersion}`;
  try {

    const result = await axios.post(uri, {
      params: {
        api_key: apiKey
      },
      data: imageUrl,
      headers: {
        "Content-Type": "x-www-form-urlencoded"
      }
    });
    console.log(result.data);
    dispatch({type: ActionTypes.SET_CLASSIFICATION, payload: result.data});
  } catch (err: any) {
    console.error(err);
    dispatch({type: ActionTypes.ERROR, payload: err.message});
  }
}

const classifyUrl = (req: ClassificationRequest) => async (dispatch: Dispatch<ClassificationActionType>) => {
    dispatch({type: ActionTypes.LOADING});
    try {
      const {apiKey, apiUrl, model, modelVersion, imageUrl} = req;
      const uri = `${apiUrl}/${model}/${modelVersion}`;
      console.log('attempting to classify image url: ', imageUrl);

      console.log(uri);

      const result = await  axios({
        method: "POST",
        url: uri,
        params: {
          api_key: apiKey,
          image: imageUrl,
        },
      });

      dispatch({type: ActionTypes.SET_CLASSIFICATION, payload: result.data});
      saveClassification(result.data as Classification);
    } catch (err: any) {
      console.error(err);
      dispatch({type: ActionTypes.ERROR, payload: err.message});
    }

  
}

const resetClassification = async (dispatch: Dispatch<ClassificationActionType>) => {
  dispatch({type: ActionTypes.CLEAR_CLASSIFICATION})
}

const saveClassification = async (classification: Classification) => {
  console.log('saveClassification: ', classification);

  try {
    const docRef = await addDoc(collection(db, "classifications"), {
        ...classification,
        verified: false,
        trained: false,   
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

  export { 
    classificationReducer,
    classifyUrl,
    classifyImage,
    resetClassification,
    saveClassification,
  }