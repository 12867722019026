import React from "react";
import semverSatisfies from "semver/functions/satisfies";
import { remoteConfig as flags } from "../config/firebase";
import "firebase/remote-config"
import { fetchAndActivate, getAll } from "firebase/remote-config";

const FlagsContext = React.createContext({});
const remoteConfig = flags;
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;


export const useFlags = () => {
  const context = React.useContext(FlagsContext);
  return context;
}

const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = React.useState(defaults);

  React.useEffect(() => {
    remoteConfig.defaultConfig = defaults;

      fetchAndActivate(remoteConfig)
      .then(activated => {
        if (!activated) console.log('not activated');
        return getAll(remoteConfig);
      })
      .then(remoteFlags => {

        const newFlags = {
          ...flags,
        };

        for (const [key, config] of Object.entries(remoteFlags)) {
          newFlags[key] = config['_value'];
        }

        setFlags(newFlags);
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

export default FlagsProvider;