import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { classificationReducer } from "./reducers/classificationRediucer";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  classification: classificationReducer
})

const store = createStore( rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;