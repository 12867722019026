/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import styles from './homepage.module.css';
import {storage} from '../../config/firebase';
import {ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import FileUploader from '../../utils/FileUploader';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../redux/store';
import { ClassificationActionType, classifyUrl, resetClassification } from '../../redux/reducers/classificationRediucer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Results from '../../components/results';
import { Grid } from '@mui/material';
import { ClassificationRequest } from '../../redux/types';
import { useFlags } from '../../utils/FlagsProvider';
import RemoteConfigKeys from '../../enum/RemoteConfigKeys';

const HomePage = () => {
  const dispatch: ThunkDispatch<RootState, undefined, ClassificationActionType> = useAppDispatch();
  const { classification } = useAppSelector((state: RootState) => state.classification);
  const [fileUploadProgress, setFileUploadProgress] = useState(0.0);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const { API_URL, API_KEY, MODEL, MODEL_VERSION } = useFlags() as RemoteConfigKeys;
  const onImageChange = (file: any) => {
   const newImage = URL.createObjectURL(file);
   setImageUrl(newImage);
   setOpen(true);

    const storageRef = ref(storage, `/images/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          setFileUploadProgress(percent);
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref)
            .then(async (url) => {
               classify(url);
            })
            .catch(async(err) => {
              console.error(err);
            })
      }
  ); 
  }

  const classify = async(url: string ) => {
    try {
      const request: ClassificationRequest = {
        imageUrl: url,
        apiUrl: API_URL,
        apiKey: API_KEY,
        model: MODEL,
        modelVersion: MODEL_VERSION
      }
      const result = await dispatch(classifyUrl(request));
      console.log(result);
    } catch (err: any) {
      console.error(err.message);
    }
  }
  const onHandleClose = async () => {
    try {
      await (dispatch(resetClassification));
      setImageUrl(null);
      setOpen(false);
    } catch (err: any) {
      console.error(err.message);
    }   
  }

  return (
    <div className={styles.container}>
      <Grid container>
          <Grid item sm={12} xs={12}>
            <p className={styles.title}>CANNAVIZION</p>
            <p className={styles.subtitle}>Is your plant showing signs of stress?<br />We created a model that will take the guesswork out of growing.</p>
            <p className={styles.subtitle}>Cannavizion can detect common issues that occur during the lifecycle of the plant, and tell you how to fix it.</p>
            <FileUploader handleFile={onImageChange} />
          </Grid>
        </Grid>
        
        <Results
          open={open}
          imageUrl={imageUrl}
          classification={classification}
          onHandleClose={onHandleClose} />
      </div>
  )
}

export default HomePage